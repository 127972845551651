import { createAction, props } from "@ngrx/store";
import { RentalApplication, RentalApplicationForm, RentalDocument as RentalDocument, RentalFormFile, RentalFormTemplate, RentalApplicationSetting, RentalRequestQueryResult, CreditCheckForm, RentalRequestForm, RentalRequestAcitivity, RentalApplicationInfoUpdateForm, RentalItem } from "app/features/client-dialog/client-dialog-model";
import { DynamicForm } from "app/shared/models/dynamic-form.mode";
import { LeadInfo } from "app/shared/models/lead-info-models";
import { IUnit } from "../client-dialog-inventory/client-dialog-inventory.model";

export const actionClientDialogApplicationRentalApplicationsRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Applications Requested',
  props<{ leadId: number, rentalRequestId: number }>()
);

export const actionClientDialogApplicationRentalApplicationsSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Applications Success',
  props<{ rentalRequestQueryResult: RentalRequestQueryResult }>()
);

export const actionClientDialogApplicationRentalApplicationsFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Applications Failed',
);

export const actionClientDialogApplicationRentalRequestRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Requested',
  props<{ rentalRequestId: number, leadId: number }>()
);

export const actionClientDialogApplicationRentalRequestSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Success',
  props<{ rentalRequestQueryResult: RentalRequestQueryResult }>()
);

export const actionClientDialogApplicationRentalRequestFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Failed',
);

export const actionClientDialogApplicationRentalApplicationRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Requested',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationRentalApplicationSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Success',
  props<{ rentalApplication: RentalApplication }>()
);

export const actionClientDialogApplicationRentalApplicationFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Failed',
);

export const actionClientDialogApplicationRentalApplicationCancelRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Cancel Requested',
  props<{ rentalApplicationId: number, reasonType: string, reason: string }>()
);

export const actionClientDialogApplicationRentalApplicationCancelSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Cancel Success',
  props<{ rentalApplication: RentalApplication }>()
);

export const actionClientDialogApplicationRentalApplicationCancelFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Cancel Failed',
  props<{ errorMessage: string }>()
);

export const actionClientDialogApplicationRentalRequestCancelRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Cancel Requested',
  props<{ rentalRequestId: number, reasonType: string, reason: string, leadId: number; }>()
);

export const actionClientDialogApplicationRentalRequestCancelSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Cancel Success',
);

export const actionClientDialogApplicationRentalRequestCancelFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Cancel Failed',
  props<{ errorMessage: string }>()
);

export const actionClientDialogApplicationRentalResetCancelErrorMessage = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Reset Cancel Error Message',
);


export const actionClientDialogApplicationRentalApplicationAddNewRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Requested',
  props<{ rentalApplicationSetting: RentalApplicationSetting }>()
);

export const actionClientDialogApplicationRentalApplicationAddNewSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Success',
  props<{ rentalApplication: RentalApplication }>()
);

export const actionClientDialogApplicationRentalApplicationAddNewFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Failed',
  props<{ errorMsg: string }>()
);

export const actionClientDialogApplicationRentalApplicationStartRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Start Requested',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationRentalApplicationStartSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Start Success',
  props<{ rentalApplication: RentalApplication }>()
);

export const actionClientDialogApplicationRentalApplicationStartFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Start Failed',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationRentalApplicationFormRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Requested',
  props<{ formId: number }>()
);

export const actionClientDialogApplicationRentalApplicationFormSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Success',
  props<{ rentalApplicationForm: DynamicForm }>()
);

export const actionClientDialogApplicationRentalApplicationFormFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Failed'
);

export const actionClientDialogApplicationRentalApplicationFormListRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form List Requested',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationRentalApplicationFormListSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form List Success',
  props<{ rentalApplicationForms: RentalApplicationForm[] }>()
);

export const actionClientDialogApplicationRentalApplicationFormListFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form List Failed'
);

export const actionClientDialogApplicationRentalRequestFormListRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form List Requested',
  props<{ rentalRequestId: number }>()
);

export const actionClientDialogApplicationRentalRequestFormListSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form List Success',
  props<{ rentalRequestForms: RentalRequestForm[] }>()
);

export const actionClientDialogApplicationRentalRequestFormListFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form List Failed'
);

export const actionClientDialogApplicationRentalApplicationFormFileListRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form File List Requested',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationRentalApplicationFormFileListSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form File List Success',
  props<{ rentalApplicationFormFiles: RentalFormFile[] }>()
);

export const actionClientDialogApplicationRentalApplicationFormFileListFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form File List Failed'
);

export const actionClientDialogApplicationRentalRequestFormFileListRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form File List Requested',
  props<{ rentalRequestId: number }>()
);

export const actionClientDialogApplicationRentalRequestFormFileListSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form File List Success',
  props<{ rentalRequestFormFiles: RentalFormFile[] }>()
);

export const actionClientDialogApplicationRentalRequestFormFileListFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form File List Failed'
);

export const actionClientDialogApplicationRentalApplicationReset = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Reset'
);

export const actionClientDialogApplicationRentalApplicationAddNewFormRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Form Requested',
  props<{ rentalApplicationId: number, formIds: number[] }>()
);

export const actionClientDialogApplicationRentalApplicationAddNewFormSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Form Success',
);

export const actionClientDialogApplicationRentalApplicationAddNewFormFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Form Failed'
);

export const actionClientDialogApplicationRentalRequestAddNewFormRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Add New Form Requested',
  props<{ rentalRequestId: number, formIds: number[] }>()
);

export const actionClientDialogApplicationRentalRequestAddNewFormSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Add New Form Success',
);

export const actionClientDialogApplicationRentalRequestAddNewFormFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Add New Form Failed'
);

export const actionClientDialogApplicationRentalAddNewCreditCheckRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Credit Check Requested',
  props<{ rentalApplicationId: number, creditCheckForm: CreditCheckForm }>()
);

export const actionClientDialogApplicationRentalAddNewCreditCheckSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Credit Check Success',
);

export const actionClientDialogApplicationRentalAddNewCreditCheckFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Credit Check Failed'
);

export const actionClientDialogApplicationRentalApplicationRemoveFormRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Form Requested',
  props<{ rentalApplicationId: number, formId: number }>()
);

export const actionClientDialogApplicationRentalApplicationRemoveFormSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Form Success',
  props<{ rentalApplicationId: number, formId: number }>()
);

export const actionClientDialogApplicationRentalApplicationRemoveFormFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Form Failed'
);

export const actionClientDialogApplicationRentalRequestRemoveFormRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Remove Form Requested',
  props<{ rentalRequestId: number, formId: number }>()
);

export const actionClientDialogApplicationRentalRequestRemoveFormSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Remove Form Success',
  props<{ rentalRequestId: number, formId: number }>()
);

export const actionClientDialogApplicationRentalRequestRemoveFormFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Remove Form Failed'
);

export const actionClientDialogApplicationRentalRemoveCertnVerificationReportRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Certn Verification Report Requested',
  props<{ reportId: number }>()
);

export const actionClientDialogApplicationRentalRemoveCertnVerificationReportSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Certn Verification Report Success',
  props<{ reportId: number }>()
);

export const actionClientDialogApplicationRentalRemoveCertnVerificationReportFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Certn Verification Report Failed'
);

export const actionClientDialogApplicationRentalRemovePlaidVerificationReportRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Plaid Verification Report Requested',
  props<{ reportId: number }>()
);

export const actionClientDialogApplicationRentalRemovePlaidVerificationReportSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Plaid Verification Report Success',
  props<{ reportId: number }>()
);

export const actionClientDialogApplicationRentalRemovePlaidVerificationReportFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Plaid Verification Report Failed'
);

export const actionClientDialogApplicationRentalApplicationRemoveFormFileRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Form File Requested',
  props<{ rentalApplicationId: number, fileId: number }>()
);

export const actionClientDialogApplicationRentalApplicationRemoveFormFileSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Form File Success',
  props<{ fileId: number }>()
);

export const actionClientDialogApplicationRentalApplicationRemoveFormFileFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Remove Form File Failed'
);

export const actionClientDialogApplicationRentalRequestRemoveFormFileRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Remove Form File Requested',
  props<{ rentalRequestId: number, fileId: number }>()
);

export const actionClientDialogApplicationRentalRequestRemoveFormFileSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Remove Form File Success',
  props<{ fileId: number }>()
);

export const actionClientDialogApplicationRentalRequestRemoveFormFileFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Remove Form File Failed'
);

export const actionClientDialogApplicationRentalApplicationFormTemplatesRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Template Forms Requested',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationRentalApplicationFormTemplatesSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Template Forms Success',
  props<{ rentalFormTemplates: RentalFormTemplate[] }>()
);

export const actionClientDialogApplicationRentalApplicationFormTemplatesFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Template Forms Failed'
);

export const actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Additional Template Forms Requested',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Additional Template Forms Success',
  props<{ rentalFormTemplates: RentalFormTemplate[] }>()
);

export const actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Additional Template Forms Failed'
);

export const actionClientDialogApplicationRentalRequestFormTemplatesRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Template Forms Requested',
  props<{ rentalRequestId: number }>()
);

export const actionClientDialogApplicationRentalRequestFormTemplatesSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Template Forms Success',
  props<{ rentalFormTemplates: RentalFormTemplate[] }>()
);

export const actionClientDialogApplicationRentalRequestFormTemplatesFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Template Forms Failed'
);


export const actionClientDialogApplicationRentalFormViewerRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Viewer Requested',
  props<{ rentalApplicationFormId: number }>()
);

export const actionClientDialogApplicationRentalFormViewerSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Viewer Success',
  props<{ document: RentalDocument[] }>()
);

export const actionClientDialogApplicationRentalPdfViewerReset = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Pdf Viewer Reset'
);

export const actionClientDialogApplicationRentalFormViewerFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Viewer Failed'
);

export const actionClientDialogApplicationResendApplicantEmailRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Resend Applicant Email Requested',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationResendApplicantEmailSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Resend Applicant Email Success'
);

export const actionClientDialogApplicationResendApplicantEmailFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Resend Applicant Email Failed'
);

export const actionClientDialogApplicationRentalApplicationUploadedFileViewerRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Uploaded Viewer Requested',
  props<{ fileId: number }>()
);

export const actionClientDialogApplicationRentalApplicationUploadedFileViewerSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Uploaded Viewer Success',
  props<{ document: RentalDocument[] }>()
);

export const actionClientDialogApplicationRentalApplicationUploadedFileViewerFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Uploaded File Viewer Failed'
);

export const actionClientDialogApplicationRentalRequestUploadedFileViewerRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Uploaded Viewer Requested',
  props<{ fileId: number }>()
);

export const actionClientDialogApplicationRentalRequestUploadedFileViewerSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Uploaded Viewer Success',
  props<{ document: RentalDocument[] }>()
);

export const actionClientDialogApplicationRentalRequestUploadedFileViewerFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Uploaded File Viewer Failed'
);

export const actionClientDialogApplicationRentalRequestFormViewerRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form File Viewer Requested',
  props<{ rentalRequestFormId: number }>()
);

export const actionClientDialogApplicationRentalRequestFormViewerSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form File Viewer Success',
  props<{ document: RentalDocument[] }>()
);

export const actionClientDialogApplicationRentalRequestFormViewerFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Form Viewer Failed'
);

export const actionClientDialogApplicationRentalCertnReportViewerRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Certn Report Viewer Requested',
  props<{ reportId: number }>()
);

export const actionClientDialogApplicationRentalCertnReportViewerSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Certn Report Viewer Success',
  props<{ document: RentalDocument[] }>()
);

export const actionClientDialogApplicationRentalCertnReportViewerFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Certn Report Viewer Failed'
);

export const actionClientDialogApplicationRentalPlaidReportViewerRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Plaid Report Viewer Requested',
  props<{ reportId: number }>()
);

export const actionClientDialogApplicationRentalPlaidReportViewerSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Plaid Report Viewer Success',
  props<{ document: RentalDocument[] }>()
);

export const actionClientDialogApplicationRentalPlaidReportViewerFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Plaid Report Viewer Failed'
);

export const actionClientDialogApplicationRentalPlaidIdentityReportViewerRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Plaid Identity Report Viewer Requested',
  props<{ reportId: number }>()
);

export const actionClientDialogApplicationRentalPlaidIdentityReportViewerSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Plaid Identity Report Viewer Success',
  props<{ document: RentalDocument[] }>()
);

export const actionClientDialogApplicationRentalPlaidIdentityReportViewerFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Plaid Identity Report Viewer Failed'
);

export const actionClientDialogApplicationRentalCertnIdentityReportViewerRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Certn Identity Report Viewer Requested',
  props<{ reportId: number }>()
);

export const actionClientDialogApplicationRentalCertnIdentityReportViewerSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Certn Identity Report Viewer Success',
  props<{ document: RentalDocument[] }>()
);

export const actionClientDialogApplicationRentalCertnIdentityReportViewerFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Certn Identity Report Viewer Failed'
);

export const actionClientDialogApplicationRentalFormEditorRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Editor Requested',
  props<{ rentalApplicationFormId: number }>()
);

export const actionClientDialogApplicationRentalFormEditorSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Editor Success',
  props<{ document: RentalDocument }>()
);

export const actionClientDialogApplicationRentalFormEditorReset = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Editor Reset'
);

export const actionClientDialogApplicationRentalFormEditorFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Editor Failed'
);

export const actionClientDialogApplicationRentalFormEditorSaveRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Editor Save Requested',
  props<{ formId: number, data: string }>()
);

export const actionClientDialogApplicationRentalFormEditorSaveSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Editor Save Success',
);

export const actionClientDialogApplicationRentalFormEditorSaveFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Form Editor Save Failed'
);

export const actionClientDialogApplicationRentalRequestFormEditorRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Request Form Editor Requested',
  props<{ rentalRequestFormId: number }>()
);

export const actionClientDialogApplicationRentalRequestFormEditorSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Request Form Editor Success',
  props<{ document: RentalDocument }>()
);

export const actionClientDialogApplicationRentalRequestFormEditorReset = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Request Form Editor Reset'
);

export const actionClientDialogApplicationRentalRequestFormEditorFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Request Form Editor Failed'
);

export const actionClientDialogApplicationRentalRequestFormEditorSaveRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Request Form Editor Save Requested',
  props<{ formId: number, data: string }>()
);

export const actionClientDialogApplicationRentalRequestFormEditorSaveSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Request Form Editor Save Success',
);

export const actionClientDialogApplicationRentalRequestFormEditorSaveFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Request Form Editor Save Failed'
);

export const actionClientDialogApplicationAddNewManualRentalApplicationDocumentRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Manual Rental Application Document Requested',
  props<{ rentalApplicationId: number, fileName: string, file: string }>()
);

export const actionClientDialogApplicationAddNewManualRentalApplicationDocumentSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Manual Rental Application Document Success'
);

export const actionClientDialogApplicationAddNewManualRentalApplicationDocumentFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Manual Rental Application Document Failed'
);

export const actionClientDialogApplicationAddNewManualRentalRequestDocumentRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Manual Rental Request Document Requested',
  props<{ rentalRequestId: number, fileName: string, file: string }>()
);

export const actionClientDialogApplicationAddNewManualRentalRequestDocumentSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Manual Rental Request Document Success'
);

export const actionClientDialogApplicationAddNewManualRentalRequestDocumentFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Add New Manual Rental Request Document Failed'
);

export const actionClientDialogApplicationRentalReadyForReviewRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Ready For Review Requested',
  props<{ leadId: number, rentalRequestId: number }>()
);

export const actionClientDialogApplicationRentalReadyForReviewSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Ready For Review Success',
);

export const actionClientDialogApplicationRentalReadyForReviewFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Ready For Review Failed',
  props<{ errorMessage: string, validationMessages: string[] }>()
);

export const actionClientDialogApplicationRentalRollbackRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Rollback Requested',
  props<{ leadId: number, rentalRequestId: number }>()
);

export const actionClientDialogApplicationRentalRollbackSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Rollback Success',
);

export const actionClientDialogApplicationRentalRollbackFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Rollback Failed',
);

export const actionClientDialogApplicationResetValidationMessageRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Reset Validation Message Requested'
);

export const actionClientDialogApplicationStartRentalRequestRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Start Rental Application Requested',
  props<{ leadId: number, rentalRequestId: number }>()
);

export const actionClientDialogApplicationStartRentalRequestSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Start Rental Application Success',
);

export const actionClientDialogApplicationStartRentalRequestFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Start Rental Application Failed',
);

export const actionClientDialogApplicationRentalApproveRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Approve Requested',
  props<{ leadId: number, rentalRequestId: number }>()
);

export const actionClientDialogApplicationRentalApproveSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Approve Success'
);

export const actionClientDialogApplicationRentalApproveFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Approve Failed',
  props<{ errorMessage: string, validationMessages: string[] }>()
);

export const actionClientDialogApplicationRentalSignRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Sign Requested',
  props<{ leadId: number, rentalRequestId: number }>()
);

export const actionClientDialogApplicationRentalSignSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Sign Success'
);

export const actionClientDialogApplicationRentalSignFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Sign Failed',
  props<{ errorMessage: string, validationMessages: string[] }>()
);

export const actionClientDialogApplicationUpdateLeasingDatesRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Update Leasing Dates Requested',
  props<{ rentalRequestId: number, moveInDate: Date, leaseStartDate: Date, leaseEndDate: Date }>()
);

export const actionClientDialogApplicationUpdateLeasingDatesSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Update Leasing Dates Success'
);

export const actionClientDialogApplicationUpdateLeasingDatesFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Update Leasing Dates Failed',
);

export const actionClientDialogApplicationRentalApplicationFileUploadLinkSendRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application File Upload Link Send Requested',
  props<{ rentalApplicationId: number, note: string }>()
);

export const actionClientDialogApplicationRentalApplicationFileUploadLinkSendSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application File Upload Link Send Success'
);

export const actionClientDialogApplicationRentalApplicationFileUploadLinkSendFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application File Upload Link Send Failed',
);

export const actionClientDialogApplicationRentalRequestActivitiesRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Activities Requested',
  props<{ rentalRequestId: number, pageNo: number, pageSize: number }>()
);

export const actionClientDialogApplicationRentalRequestActivitiesLoaded = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Activities Loaded',
  props<{ rentalRequestActivities: RentalRequestAcitivity[], pageNo: number, pageSize: number, totalRecords: number }>()
);

export const actionClientDialogApplicationRentalRequestActivitiesFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Activities Failed',
);

export const actionClientDialogApplicationRentalRequestActivitiesMoreRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Activities More Requested',
  props<{ rentalRequestId: number, pageNo: number, pageSize: number }>()
);

export const actionClientDialogApplicationRentalRequestActivitiesMoreLoaded = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Activities More Loaded',
  props<{ rentalRequestActivities: RentalRequestAcitivity[], pageNo: number, pageSize: number, totalRecords: number }>()
);

export const actionClientDialogApplicationRentalRequestActivitiesMoreFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Activities More Failed',
);

export const actionClientDialogApplicationToggleShowRentalRequestActivities = createAction(
  '[Client Dialog Rental Application] Client Application Toggle Show Rental Request Activities',
  props<{ ifShowRentalRequestActivities: boolean }>()
);

export const actionClientDialogApplicationRentalInfoUpdateRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Info Update Requested',
  props<{ rentalApplicationId: number, updateInfo: RentalApplicationInfoUpdateForm }>()
);

export const actionClientDialogApplicationRentalInfoUpdateSuccess = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Info Update Success',
  props<{ rentalApplicationId: number }>()
);

export const actionClientDialogApplicationAddRentalItemsToRentalReqest = createAction(
  '[Client Dialog Rental Application] Client Application Add Rental Items To Rental Reqest',
  props<{ rentalRequestId: number, rentalItemIds: number[] }>()
);

export const actionClientDialogApplicationAddRentalItemsToRentalReqestSucceed = createAction(
  '[Client Dialog Rental Application] Client Application Add Rental Items To Rental Reqest Succeed',
  props<{ rentalRequestId: number }>()
);

export const actionClientDialogApplicationAddRentalItemsToRentalReqestFailed = createAction(
  '[Client Dialog Rental Application] Client Application Add Rental Items To Rental Reqest Failed',
);

export const actionClientDialogApplicationRemoveRentalItemFromRentalReqest = createAction(
  '[Client Dialog Rental Application] Client Application Remove Rental Item From Rental Reqest',
  props<{ rentalRequestId: number, rentalItemId: number }>()
);

export const actionClientDialogApplicationRemoveRentalItemFromRentalReqestSucceed = createAction(
  '[Client Dialog Rental Application] Client Application Remove Rental Item From Rental Reqest Succeed',
  props<{ rentalRequestId: number }>()
);

export const actionClientDialogApplicationRemoveRentalItemFromRentalReqestFailed = createAction(
  '[Client Dialog Rental Application] Client Application Remove Rental Item From Rental Reqest Failed',
);

export const actionClientDialogApplicationGetRentalItemsForRentalReqest = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Items For Rental Reqest',
  props<{ rentalRequestId: number }>()
);

export const actionClientDialogApplicationGetRentalItemsForRentalReqestLoaded = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Items For Rental Reqest Loaded',
  props<{ rentalItems: RentalItem[] }>()
);

export const actionClientDialogApplicationGetRentalItemsForRentalReqestFailed = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Items For Rental Reqest Failed',
);

export const actionClientDialogApplicationGetRentalItemTypesForRentalReqest = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Item Types For Rental Reqest',
  props<{ rentalRequestId: number }>()
);

export const actionClientDialogApplicationGetRentalItemTypesForRentalReqestLoaded = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Item Types For Rental Reqest Succeed',
  props<{ rentalItemTypes: string[] }>()
);

export const actionClientDialogApplicationGetRentalItemTypesForRentalReqestFailed = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Item Types For Rental Reqest Failed',
);

export const actionClientDialogApplicationGetRentalItemOptionsForRentalReqest = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Item Options For Rental Reqest',
  props<{ rentalRequestId: number, rentalItemType: string, page: number, pageSize: number }>()
);

export const actionClientDialogApplicationGetRentalItemOptionsForRentalReqestLoaded = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Item Options For Rental Reqest Succeed',
  props<{ rentalItemOptions: RentalItem[], totalRecords: number }>()
);

export const actionClientDialogApplicationGetRentalItemTypeSelected = createAction(
  '[Client Dialog Rental Application] Client Application Rental Item Type Selected',
  props<{ rentalItemType: string }>()
);

export const actionClientDialogApplicationRentalItemOptionsPageChange = createAction(
  '[Client Dialog Rental Application] Client Application Rental Item Options Page Change',
  props<{ page: number }>()
);

export const actionClientDialogApplicationRentalItemOptionsNextPage = createAction(
  '[Client Dialog Rental Application] Client Application Rental Item Options Next Page',
);

export const actionClientDialogApplicationRentalItemStateReset = createAction(
  '[Client Dialog Rental Application] Client Application Rental Item State Reset',
);

export const actionClientDialogApplicationGetRentalItemOptionsForRentalReqestFailed = createAction(
  '[Client Dialog Rental Application] Client Application Get Rental Item Options For Rental Reqest Failed',
);

export const actionClientDialogApplicationRentalInfoUpdateFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Application Info Update Failed'
);

export const actionClientDialogApplicationLeasingDocumentSignatureResetRequested = createAction(
  '[Client Dialog Rental Application] Client Application Leasing Document Signature Reset Requested',
  props<{ formId: number }>()
);

export const actionClientDialogApplicationLeasingDocumentSignatureResetSucceed = createAction(
  '[Client Dialog Rental Application] Client Application Leasing Document Signature Reset Succeed',
);

export const actionClientDialogApplicationLeasingDocumentSignatureResetFailed = createAction(
  '[Client Dialog Rental Application] Client Application Leasing Document Signature Reset Succeed',
);

export const actionClientDialogApplicationGetLeadInfoByLeadIdRequested = createAction(
  '[Client Dialog Rental Application] Client Application Get Lead Info By LeadId Requested',
  props<{ leadId: number }>()
);

export const actionClientDialogApplicationGetLeadInfoByLeadIdLoaded = createAction(
  '[Client Dialog Rental Application] Client Application Get Lead Info By LeadId Loaded',
  props<{ leadInfo: LeadInfo }>()
);

export const actionClientDialogApplicationUpdateApplicantLeadInfo = createAction(
  '[Client Dialog Rental Application] Client Application Update Applicant Lead Info',
  props<{ leadId: number, firstName: string, lastName: string, emails: string[], phoneNumbers: string[] }>()
);

export const actionClientDialogApplicationGetLeadInfoByLeadIdFailed = createAction(
  '[Client Dialog Rental Application] Client Application Get Lead Info By LeadId Failed',
);

export const actionClientDialogApplicationUnitsForRentalRequestRequested = createAction(
  '[Client Dialog Rental Application] Client Application Units For Rental Request Requested',
  props<{ inventoryId: number }>()
);

export const actionClientDialogApplicationUnitsForRentalRequestLoaded = createAction(
  '[Client Dialog Rental Application] Client Application Units For Rental Request Loaded',
  props<{ units: IUnit[] }>()
);

export const actionClientDialogApplicationUnitsForRentalRequestFailed = createAction(
  '[Client Dialog Rental Application] Client Application Units For Rental Request Failed',
);

export const actionClientDialogApplicationRentalRequestUnitUpdateRequested = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Unit Update Requested',
  props<{ rentalRequestId: number, unitId: number, rentPrice: number }>()
);

export const actionClientDialogApplicationRentalRequestUnitUpdateSucceed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Unit Update Succeed',
  props<{ unitId: number, rentPrice: number }>()
);

export const actionClientDialogApplicationRentalRequestUnitUpdateFailed = createAction(
  '[Client Dialog Rental Application] Client Application Rental Request Unit Update Failed',
);



export const clientDialogApplicationRentalApplicationActions = {
  actionClientDialogApplicationRentalApplicationsRequested,
  actionClientDialogApplicationRentalApplicationsSuccess,
  actionClientDialogApplicationRentalApplicationReset,
  actionClientDialogApplicationRentalRequestRequested,
  actionClientDialogApplicationRentalRequestSuccess,
  actionClientDialogApplicationRentalApplicationRequested,
  actionClientDialogApplicationRentalApplicationSuccess,
  actionClientDialogApplicationRentalApplicationAddNewRequested,
  actionClientDialogApplicationRentalApplicationAddNewSuccess,
  actionClientDialogApplicationRentalApplicationCancelRequested,
  actionClientDialogApplicationRentalApplicationCancelSuccess,
  actionClientDialogApplicationRentalRequestCancelRequested,
  actionClientDialogApplicationRentalRequestCancelSuccess,
  actionClientDialogApplicationRentalApplicationStartRequested,
  actionClientDialogApplicationRentalApplicationStartSuccess,
  actionClientDialogApplicationRentalApplicationFormRequested,
  actionClientDialogApplicationRentalApplicationFormSuccess,
  actionClientDialogApplicationRentalApplicationFormListRequested,
  actionClientDialogApplicationRentalApplicationFormListSuccess,
  actionClientDialogApplicationRentalApplicationAddNewFormRequested,
  actionClientDialogApplicationRentalApplicationAddNewFormSuccess,
  actionClientDialogApplicationRentalRequestAddNewFormRequested,
  actionClientDialogApplicationRentalRequestAddNewFormSuccess,
  actionClientDialogApplicationRentalApplicationFormTemplatesRequested,
  actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesRequested,
  actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesSuccess,
  actionClientDialogApplicationRentalApplicationFormTemplatesSuccess,
  actionClientDialogApplicationRentalRequestFormTemplatesRequested,
  actionClientDialogApplicationRentalRequestFormTemplatesSuccess,
  actionClientDialogApplicationRentalApplicationRemoveFormRequested,
  actionClientDialogApplicationRentalApplicationRemoveFormSuccess,
  actionClientDialogApplicationRentalFormViewerRequested,
  actionClientDialogApplicationRentalFormViewerSuccess,
  actionClientDialogApplicationRentalPdfViewerReset,
  actionClientDialogApplicationRentalRequestFormViewerRequested,
  actionClientDialogApplicationRentalRequestFormViewerSuccess,
  actionClientDialogApplicationRentalFormEditorRequested,
  actionClientDialogApplicationRentalFormEditorSuccess,
  actionClientDialogApplicationRentalFormEditorReset,
  actionClientDialogApplicationRentalFormEditorSaveRequested,
  actionClientDialogApplicationRentalFormEditorSaveSuccess,
  actionClientDialogApplicationAddNewManualRentalApplicationDocumentRequested,
  actionClientDialogApplicationAddNewManualRentalApplicationDocumentSuccess,
  actionClientDialogApplicationRentalApplicationRemoveFormFileRequested,
  actionClientDialogApplicationRentalApplicationRemoveFormFileSuccess,
  actionClientDialogApplicationRentalApplicationFormFileListRequested,
  actionClientDialogApplicationRentalApplicationFormFileListSuccess,
  actionClientDialogApplicationRentalCertnReportViewerRequested,
  actionClientDialogApplicationRentalCertnReportViewerSuccess,
  actionClientDialogApplicationRentalPlaidReportViewerRequested,
  actionClientDialogApplicationRentalPlaidReportViewerSuccess,
  actionClientDialogApplicationRentalReadyForReviewRequested,
  actionClientDialogApplicationRentalReadyForReviewSuccess,
  actionClientDialogApplicationRentalApproveRequested,
  actionClientDialogApplicationRentalApproveSuccess,
  actionClientDialogApplicationRentalSignRequested,
  actionClientDialogApplicationRentalSignSuccess,
  actionClientDialogApplicationRentalRequestFormEditorRequested,
  actionClientDialogApplicationRentalRequestFormEditorSuccess,
  actionClientDialogApplicationRentalRequestFormEditorReset,
  actionClientDialogApplicationRentalRequestFormEditorSaveRequested,
  actionClientDialogApplicationRentalRequestFormEditorSaveSuccess,
  actionClientDialogApplicationRentalApplicationUploadedFileViewerRequested,
  actionClientDialogApplicationRentalApplicationUploadedFileViewerSuccess,
  actionClientDialogApplicationAddNewManualRentalRequestDocumentRequested,
  actionClientDialogApplicationAddNewManualRentalRequestDocumentSuccess,
  actionClientDialogApplicationRentalRemoveCertnVerificationReportRequested,
  actionClientDialogApplicationRentalRemoveCertnVerificationReportSuccess,
  actionClientDialogApplicationRentalRemovePlaidVerificationReportRequested,
  actionClientDialogApplicationRentalRemovePlaidVerificationReportSuccess,
  actionClientDialogApplicationRentalPlaidIdentityReportViewerRequested,
  actionClientDialogApplicationRentalPlaidIdentityReportViewerSuccess,
  actionClientDialogApplicationUpdateLeasingDatesRequested,
  actionClientDialogApplicationUpdateLeasingDatesSuccess,
  actionClientDialogApplicationRentalAddNewCreditCheckRequested,
  actionClientDialogApplicationRentalAddNewCreditCheckSuccess,
  actionClientDialogApplicationRentalRequestFormListRequested,
  actionClientDialogApplicationRentalRequestFormListSuccess,
  actionClientDialogApplicationRentalRequestRemoveFormRequested,
  actionClientDialogApplicationRentalRequestRemoveFormSuccess,
  actionClientDialogApplicationStartRentalRequestRequested,
  actionClientDialogApplicationStartRentalRequestSuccess,
  actionClientDialogApplicationRentalRequestFormFileListRequested,
  actionClientDialogApplicationRentalRequestFormFileListSuccess,
  actionClientDialogApplicationRentalRequestRemoveFormFileRequested,
  actionClientDialogApplicationRentalRequestRemoveFormFileSuccess,
  actionClientDialogApplicationRentalRequestUploadedFileViewerRequested,
  actionClientDialogApplicationRentalRequestUploadedFileViewerSuccess,
  actionClientDialogApplicationResetValidationMessageRequested,
  actionClientDialogApplicationResendApplicantEmailRequested,
  actionClientDialogApplicationResendApplicantEmailSuccess,
  actionClientDialogApplicationRentalCertnIdentityReportViewerRequested,
  actionClientDialogApplicationRentalCertnIdentityReportViewerSuccess,
  actionClientDialogApplicationRentalApplicationFileUploadLinkSendRequested,
  actionClientDialogApplicationRentalApplicationFileUploadLinkSendSuccess,
  actionClientDialogApplicationRentalRequestActivitiesRequested,
  actionClientDialogApplicationRentalRequestActivitiesLoaded,
  actionClientDialogApplicationRentalRequestActivitiesMoreRequested,
  actionClientDialogApplicationRentalRequestActivitiesMoreLoaded,
  actionClientDialogApplicationToggleShowRentalRequestActivities,
  actionClientDialogApplicationRentalInfoUpdateRequested,
  actionClientDialogApplicationRentalInfoUpdateSuccess,
  actionClientDialogApplicationAddRentalItemsToRentalReqest,
  actionClientDialogApplicationAddRentalItemsToRentalReqestSucceed,
  actionClientDialogApplicationRemoveRentalItemFromRentalReqest,
  actionClientDialogApplicationRemoveRentalItemFromRentalReqestSucceed,
  actionClientDialogApplicationGetRentalItemsForRentalReqest,
  actionClientDialogApplicationGetRentalItemsForRentalReqestLoaded,
  actionClientDialogApplicationGetRentalItemTypesForRentalReqest,
  actionClientDialogApplicationGetRentalItemTypesForRentalReqestLoaded,
  actionClientDialogApplicationGetRentalItemOptionsForRentalReqest,
  actionClientDialogApplicationGetRentalItemOptionsForRentalReqestLoaded,
  actionClientDialogApplicationGetRentalItemTypeSelected,
  actionClientDialogApplicationRentalItemOptionsPageChange,
  actionClientDialogApplicationRentalItemOptionsNextPage,
  actionClientDialogApplicationRentalItemStateReset,
  actionClientDialogApplicationRentalRollbackRequested,
  actionClientDialogApplicationRentalRollbackSuccess,
  actionClientDialogApplicationRentalResetCancelErrorMessage,
  actionClientDialogApplicationLeasingDocumentSignatureResetRequested,
  actionClientDialogApplicationLeasingDocumentSignatureResetSucceed,
  actionClientDialogApplicationGetLeadInfoByLeadIdRequested,
  actionClientDialogApplicationGetLeadInfoByLeadIdLoaded,
  actionClientDialogApplicationUpdateApplicantLeadInfo,
  actionClientDialogApplicationUnitsForRentalRequestRequested,
  actionClientDialogApplicationUnitsForRentalRequestLoaded,
  actionClientDialogApplicationRentalRequestUnitUpdateRequested,
  actionClientDialogApplicationRentalRequestUnitUpdateSucceed
}

export const clientDialogApplicationRentalApplicationFailedActions = {
  actionClientDialogApplicationRentalApplicationsFailed,
  actionClientDialogApplicationRentalRequestFailed,
  actionClientDialogApplicationRentalApplicationAddNewFailed,
  actionClientDialogApplicationRentalApplicationCancelFailed,
  actionClientDialogApplicationRentalApplicationStartFailed,
  actionClientDialogApplicationRentalApplicationFormFailed,
  actionClientDialogApplicationRentalApplicationAddNewFormFailed,
  actionClientDialogApplicationRentalRequestAddNewFormFailed,
  actionClientDialogApplicationRentalApplicationFormTemplatesFailed,
  actionClientDialogApplicationRentalRequestFormTemplatesFailed,
  actionClientDialogApplicationRentalApplicationFormListFailed,
  actionClientDialogApplicationRentalApplicationRemoveFormFailed,
  actionClientDialogApplicationRentalFormViewerFailed,
  actionClientDialogApplicationRentalRequestFormViewerFailed,
  actionClientDialogApplicationRentalFormEditorFailed,
  actionClientDialogApplicationRentalFormEditorSaveFailed,
  actionClientDialogApplicationAddNewManualRentalApplicationDocumentFailed,
  actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesFailed,
  actionClientDialogApplicationRentalApplicationRemoveFormFileFailed,
  actionClientDialogApplicationRentalApplicationFormFileListFailed,
  actionClientDialogApplicationRentalCertnReportViewerFailed,
  actionClientDialogApplicationRentalPlaidReportViewerFailed,
  actionClientDialogApplicationRentalApplicationFailed,
  actionClientDialogApplicationRentalReadyForReviewFailed,
  actionClientDialogApplicationRentalApproveFailed,
  actionClientDialogApplicationRentalSignFailed,
  actionClientDialogApplicationRentalRequestCancelFailed,
  actionClientDialogApplicationRentalRequestFormEditorFailed,
  actionClientDialogApplicationRentalRequestFormEditorSaveFailed,
  actionClientDialogApplicationRentalApplicationUploadedFileViewerFailed,
  actionClientDialogApplicationRentalRemoveCertnVerificationReportFailed,
  actionClientDialogApplicationRentalRemovePlaidVerificationReportFailed,
  actionClientDialogApplicationRentalPlaidIdentityReportViewerFailed,
  actionClientDialogApplicationUpdateLeasingDatesFailed,
  actionClientDialogApplicationRentalAddNewCreditCheckFailed,
  actionClientDialogApplicationRentalRequestFormListFailed,
  actionClientDialogApplicationRentalRequestRemoveFormFailed,
  actionClientDialogApplicationStartRentalRequestFailed,
  actionClientDialogApplicationAddNewManualRentalRequestDocumentFailed,
  actionClientDialogApplicationRentalRequestFormFileListFailed,
  actionClientDialogApplicationRentalRequestRemoveFormFileFailed,
  actionClientDialogApplicationRentalRequestUploadedFileViewerFailed,
  actionClientDialogApplicationResendApplicantEmailFailed,
  actionClientDialogApplicationRentalCertnIdentityReportViewerFailed,
  actionClientDialogApplicationRentalApplicationFileUploadLinkSendFailed,
  actionClientDialogApplicationRentalRequestActivitiesFailed,
  actionClientDialogApplicationRentalRequestActivitiesMoreFailed,
  actionClientDialogApplicationRentalInfoUpdateFailed,
  actionClientDialogApplicationAddRentalItemsToRentalReqestFailed,
  actionClientDialogApplicationRemoveRentalItemFromRentalReqestFailed,
  actionClientDialogApplicationGetRentalItemsForRentalReqestFailed,
  actionClientDialogApplicationGetRentalItemTypesForRentalReqestFailed,
  actionClientDialogApplicationGetRentalItemOptionsForRentalReqestFailed,
  actionClientDialogApplicationRentalRollbackFailed,
  actionClientDialogApplicationLeasingDocumentSignatureResetFailed,
  actionClientDialogApplicationGetLeadInfoByLeadIdFailed,
  actionClientDialogApplicationUnitsForRentalRequestFailed,
  actionClientDialogApplicationRentalRequestUnitUpdateFailed
}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/features/client-dialog/client-dialog.state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectClientDialogRentalApplicationIsRentalRequestUnitUpdating, selectClientDialogRentalRequestUnitsToSelect } from '../../client-dialog-rental-application.selector';
import { IUnit } from '../../../client-dialog-inventory/client-dialog-inventory.model';
import { MatProgressButtonOptions } from 'app/shared/components/spinner-button/spinner-button.interface';
import { actionClientDialogApplicationRentalRequestUnitUpdateRequested } from '../../client-dialog-rental-application.action';

@Component({
  selector: 'dq-client-dialog-rental-application-units',
  templateUrl: './client-dialog-rental-application-units.component.html',
  styleUrl: './client-dialog-rental-application-units.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationUnitsComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    @Inject('dialogData') private dialogData: any,) {
    this.rentalRequestId = dialogData.rentalRequestId;
  }

  rentalRequestId: number;
  units$: Observable<IUnit[]>

  selectedUnit: IUnit = null;
  hoveredUnit: IUnit = null;

  unsubscribe$: Subject<void> = new Subject<void>();
  isUpdating$: Observable<boolean>;

  spinnerCreateButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Confirm',
    flat: true, spinnerSize: 18,
    fullWidth: true,
    disabled: true,
    mode: 'indeterminate',
  };

  ngOnInit(): void {
    this.units$ = this.store.select(selectClientDialogRentalRequestUnitsToSelect);
    this.isUpdating$ = this.store.select(selectClientDialogRentalApplicationIsRentalRequestUnitUpdating);
    this.isUpdating$.pipe(takeUntil(this.unsubscribe$)).subscribe(isUpdating => {
      if (isUpdating) {
        this.spinnerCreateButtonOptions.active = true;
      } else {
        this.spinnerCreateButtonOptions.active = false;
      }
      this.cdr.markForCheck();
    });
  }

  selectUnit(unit: any) {
    this.selectedUnit = unit;
    this.spinnerCreateButtonOptions.disabled = false;
    this.cdr.markForCheck();
  }

  updateUnit() {
    if (this.spinnerCreateButtonOptions.active) {
      return
    }
    this.store.dispatch(actionClientDialogApplicationRentalRequestUnitUpdateRequested(
      {
        rentalRequestId: this.rentalRequestId,
        unitId: this.selectedUnit.id,
        rentPrice: this.selectedUnit.price
      }
    ));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

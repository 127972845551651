export const CLIENT_DIALOG_ID = 'client_dialog';


export interface InitRentalRequest {
    unitId: number;
    leasingRepId: number;
    leadId: number;
    moveInDate: number;
    leaseStartDate: number;
    leaseEndDate: number;
    rentalItems: number[];
    leasingLanguage: string;
}

export interface RentalRequestQueryResult {
    rentalRequest: RentalRequest,
    rentalRequestListItems: RentalRequestListItem[],
    rentalApplication: RentalApplication,
    rentalApplicationListItems: RentalApplicationListItem[]
}

export interface RentalRequest {
    id?: number;
    rentalPrice?: number;
    moveInDate?: Date;
    leaseStartDate?: Date;
    leaseEndDate?: Date;
    status: string;
    isStorageRequested: boolean,
    isParkingRequested: boolean,
    inventoryFriendlyName: string;
    buildingPropertyAddress: string;
    businessOperatorFriendlyName: string
    leasingRepUserId: number;
    leasingRepFriendlyName: string;
    buildingPropertyInventoryId: number;
    rentalItems: RentalItem[];
    rentalRequestFormFiles: RentalFormFile[];
    rentalRequestForms: RentalRequestForm[];
    rentalRequestStatuses: RentalRequestStatuses[];
    rentalRequestDepositTransactions: RentalRequestDepositTransaction[];
    buildingPropertyRentalItemRequestRel: BuildingPropertyRentalItemRequestRel[];
    creationDate?: Date;
    lastUpdatedDate?: Date;
    type?: string;
}

export interface RentalRequestAcitivity {
    id: number;
    leadId: number;
    userId: number;
    rentalRequestId: number;
    operatorName: string;
    description: string;
    activityType: string;
    creationDate: Date;
}

export interface RentalApplication {
    id?: number;
    leadId: number;
    applicantFirstName: string;
    applicantLastName: string;
    applicantEmail: string;
    applicantPhoneNumber: string;
    applicantStreetAddress?: string;
    applicantCity?: string;
    applicantPostalCode?: string;
    applicantState?: string;
    applicantCountry?: string;
    isDepositPayer?: string;
    isManualDocument?: string;
    sin?: string;
    applicantBirthDate?: string;
    applicantEmploymentStatus?: string;
    applicantLanguage: string;
    applicantType: string;
    rentalRequestId?: number;
    status?: string;
    creationDate?: Date;
    lastUpdatedDate?: Date;
    rentalApplicationForms?: RentalApplicationForm[],
    rentalApplicationFormFiles?: RentalFormFile[],
    rentalApplicationCertnReports?: RentalApplicationVerificationReport[],
    rentalApplicationPlaidReports?: RentalApplicationVerificationReport[]
}

export interface RentalApplicationAddNew {
    applicantFirstName: string;
    applicantLastName: string;
    applicantEmail: string;
    applicantPhoneNumber: string;
    applicantEmploymentStatus: string;
    applicantLanguage: string;
    applicantType: string;
}

export interface RentalApplicationSetting {
    rentalRequestId: number;
    applicantFirstName: string;
    applicantLastName: string;
    applicantEmail?: string;
    applicantPhoneNumber?: string;
    applicantEmploymentStatus: string;
    applicantLanguage: string;
    applicantType: string;
    isDepositPayer: boolean;
    isManualDocument: boolean;
}

export interface CreditCheckForm {
    rentalApplicationId: number;
    applicantFirstName: string;
    applicantLastName: string;
    applicantEmail: string;
    applicantPhoneNumber: string;
    applicantStreetAddress: string;
    applicantCity: string;
    applicantPostalCode: string;
    applicantState: string;
    applicantCountry: string;
    applicantBirthDate: Date;
}

export interface RentalApplicationListItem {
    id: number;
    applicantFirstName: string;
    applicantLastName: string;
    applicantType: string;
    status: string;
    creationDate: Date;
}

export interface RentalRequestDepositTransaction {
    id: number;
    transactionAmount: number;
    transactionDate: Date;
    transactionStatus: string;
    transactionType: string;
    fullName: string;
    rentalApplicationId: string;
}

export interface RentalRequestListItem {
    id: number;
    inventoryFriendlyName: string;
    status: string;
    creationDate: Date;
}

export interface RentalFormTemplate {
    id: number;
    type: string;
    friendlyName: string;
    signatures: RentalRequestFormSignature[]
}

export interface RentalRequestFormSignature {
    rentalApplicationId: number;
    applicantName: string;
    signDate?: Date;
}

export interface RentalRequestForm {
    id: number;
    type: string;
    friendlyName: string;
    status: string;
}

export interface RentalApplicationForm {
    id: number;
    type: string;
    friendlyName: string;
    status: string;
    isSerialNumberRequired: boolean;
}

export interface RentalFormFile {
    id: number;
    fileName: string;
    note: string;
}

export interface RentalApplicationVerificationReport {
    id: number;
    reportStatus: string;
    reportType: string;
    creationDate: Date;
}

export interface RentalItem {
    id: number;
    type: string;
    friendlyName: string;
    price: number;
}

export interface LeasingRep {
    id: number;
    firstName: string;
    lastName: string;
}

export interface RentalRequestStatuses {
    id?: number;
    status: string;
    statusDate: Date;
    rentalRequestId: number;
}

export interface BuildingPropertyRentalItemRequestRel {
    buildingPropertyRentalItemId: number;
    rentalRequestId: number;
}

export interface TinyMceInventoryBuildingOptions {
    text: string;
    value: string;
}

export interface RentalDocument {
    data: any;
    document: string;
    documentUrl: string;
    fileType: string;
}

export interface RentalApplicationInfoUpdateForm {
    rentalApplicationId: number;
    applicantFirstName: string;
    applicantLastName: string;
    applicantEmail: string;
    applicantPhoneNumber: string;
    applicantStreetAddress: string;
    applicantCity: string;
    applicantPostalCode: string;
    applicantState: string;
    applicantCountry: string;
    applicantBirthDate: Date;
    leadId?: number;
}

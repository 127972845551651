import { Action, createReducer, on } from "@ngrx/store";
import { ClientDialogClientRentalApplicationState } from "app/features/client-dialog/client-dialog.state";
import { clientDialogApplicationRentalApplicationActions, clientDialogApplicationRentalApplicationFailedActions } from "./client-dialog-rental-application.action";
import { RentalApplicationListItem } from "../../client-dialog-model";

export const initialState: ClientDialogClientRentalApplicationState = {
    isRentalRequestLoaded: false,
    isRentalApplicationLoaded: false,
    isRentalApplicationSending: false,
    isRentalApplicationCancelling: false,
    isRentalApplicationVerificationReportRemoving: false,
    isRentalApplicationFormRemoving: false,
    isRentalApplicationFormSending: false,
    isRentalApplicationCreditCheckFormSending: false,
    isRentalApplicationAddNewFormSending: false,
    isRentalApplicationAddNewFormLoading: true,
    isRentalApplicationFormLoading: true,
    isRentalApplicationFormListLoading: false,
    isRentalRequestFormListLoading: false,
    isRentalApplicationEmailSending: false,
    isRentalApplicationFormViewerLoading: true,
    isRentalApplicationFormEditorLoading: true,
    isRentalApplicationFormEditorSaving: false,
    isRentalApplicationFormApproveAndSigning: false,
    isRentalRequestStatusUpdating: false,
    isRentalApplicationFormFileRemoving: false,
    isRentalApplicationFormFileListLoading: false,
    isRentalApplicationStarting: false,
    isRentalRequestUnitUpdating: false,
    isRentalRequestDateSending: false,
    isRentalFileUploadLinkSending: false,
    isRentalApplicationinfoUpdating: false,
    isLeasingSignatureReseting: false,
    cancelErrorMessage: '',
    rentalRequestValidationMessage: {
        errorMessage: '',
        validationMessages: []
    },
    rentalApplicationFormViewerDocument: [],
    rentalApplicationFormEditorDocument: {
        data: '',
        document: '',
        documentUrl: '',
        fileType: ''
    },
    rentalApplicationAddEdit: {
        applicantFirstName: '',
        applicantLastName: '',
        applicantEmail: '',
        applicantPhoneNumber: '',
        applicantType: '',
        applicantLanguage: 'en',
        applicantEmploymentStatus: ''
    },
    rentalRequest: {
        id: null,
        rentalPrice: 0,
        moveInDate: new Date(),
        leaseStartDate: new Date(),
        leaseEndDate: new Date(),
        status: '',
        inventoryFriendlyName: '',
        buildingPropertyAddress: '',
        businessOperatorFriendlyName: '',
        leasingRepUserId: 0,
        leasingRepFriendlyName: '',
        buildingPropertyInventoryId: 0,
        rentalItems: [],
        rentalRequestStatuses: [],
        buildingPropertyRentalItemRequestRel: [],
        rentalRequestDepositTransactions: [],
        creationDate: new Date(),
        lastUpdatedDate: new Date(),
        isStorageRequested: false,
        isParkingRequested: false,
        rentalRequestForms: [],
        rentalRequestFormFiles: [],
        type: '',
    },
    rentalRequestListItems: [],
    rentalApplication: {
        id: 0,
        leadId: 0,
        applicantFirstName: '',
        applicantLastName: '',
        applicantEmail: '',
        applicantPhoneNumber: '',
        applicantStreetAddress: '',
        applicantCity: '',
        applicantPostalCode: '',
        applicantState: '',
        applicantCountry: '',
        applicantType: '',
        sin: '',
        applicantBirthDate: '',
        applicantEmploymentStatus: '',
        applicantLanguage: '',
        rentalRequestId: 0,
        status: '',
        creationDate: new Date(),
        lastUpdatedDate: new Date(),
        rentalApplicationForms: [],
        rentalApplicationFormFiles: [],
        rentalApplicationCertnReports: [],
        rentalApplicationPlaidReports: []
    },
    rentalApplicationLeadInfo: {
        id: 0,
        firstName: '',
        lastName: '',
        emails: [],
        username: '',
        phoneNumbers: [],
        phoneNumberCountryCode: '',
        interests: '',
        leadSourceId: 0,
        leadSources: [],
        leadBuildings: [],
        lastCommunicationDate: '',
        leadListTags: [],
        leadAssignedUsers: [],
        phoneNumberBlockInfo: []
    },
    rentalApplicationUnitsToSelect: [],
    rentalApplicationListItems: [],
    rentalFormTemplates: [],
    selectedRentalApplicationForm: {
        id: 0,
        friendlyName: '',
        jsonFormTemplate: '',
        jsonFormValues: '',
    },
    addNewApplicationError: '',
    rentalRequestActivities: [],
    isRentalRequestActivitiesLoaded: false,
    ifShowRentalRequestActivities: false,
    activityTotalRecords: 0,
    activittPageSize: 10,
    activityPageNo: 1,
    isActivitiesLoadingMore: false,
    rentalItemOptions: [],
    rentalItemTypes: [],
    isRentalItemUpdating: false,
    isRentalItemOptionsLoaded: false,
    isRentalItemsLoaded: false,
    isRentalItemTypesLoaded: false,
    rentalItemSelected: '',
    rentalItemOptionsPagination: {
        page: 1,
        pageSize: 20
    },
    rentalItemOptionsTotalRecords: 0
}

const reducer = createReducer(
    initialState,
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsRequested,
        (state) => ({
            ...state,
            isRentalRequestLoaded: false,
            isRentalApplicationLoaded: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationsSuccess,
        (state, { rentalRequestQueryResult }) => ({
            ...state,
            isRentalRequestLoaded: true,
            isRentalApplicationLoaded: true,
            rentalRequest: rentalRequestQueryResult.rentalRequest,
            rentalRequestListItems: rentalRequestQueryResult.rentalRequestListItems,
            rentalApplication: rentalRequestQueryResult.rentalApplication,
            rentalApplicationListItems: rentalRequestQueryResult.rentalApplicationListItems
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationsFailed,
        (state) => ({
            ...state,
            isRentalRequestLoaded: true,
            isRentalApplicationLoaded: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRequested,
        (state) => ({
            ...state,
            isRentalRequestLoaded: false,
            isRentalApplicationLoaded: false,
            ifShowRentalRequestActivities: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestSuccess,
        (state, { rentalRequestQueryResult }) => ({
            ...state,
            isRentalRequestLoaded: true,
            isRentalApplicationLoaded: true,
            rentalRequest: rentalRequestQueryResult.rentalRequest,
            rentalApplication: rentalRequestQueryResult.rentalApplication,
            rentalApplicationListItems: rentalRequestQueryResult.rentalApplicationListItems
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFailed,
        (state) => ({
            ...state,
            isRentalRequestLoaded: true,
            isRentalApplicationLoaded: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRequested,
        (state) => ({
            ...state,
            isRentalApplicationLoaded: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationSuccess,
        (state, { rentalApplication }) => ({
            ...state,
            isRentalApplicationLoaded: true,
            rentalApplication: rentalApplication,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFailed,
        (state) => ({
            ...state,
            isRentalApplicationLoaded: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewRequested,
        (state) => ({
            ...state,
            isRentalApplicationSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewSuccess,
        (state, { rentalApplication }) => ({
            ...state,
            isRentalApplicationSending: false,
            rentalApplication: rentalApplication,
            rentalApplicationListItems: [...state.rentalApplicationListItems, {
                id: rentalApplication.id,
                applicantFirstName: rentalApplication.applicantFirstName,
                applicantLastName: rentalApplication.applicantLastName,
                applicantType: rentalApplication.applicantType,
                status: rentalApplication.status,
                creationDate: rentalApplication.creationDate
            } as RentalApplicationListItem],
            addNewApplicationError: ''
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationAddNewFailed,
        (state, { errorMsg }) => ({
            ...state,
            isRentalApplicationSending: false,
            addNewApplicationError: errorMsg
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationCancelRequested,
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestCancelRequested,
        (state) => ({
            ...state,
            isRentalApplicationCancelling: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestCancelSuccess,
        (state) => ({
            ...state,
            isRentalApplicationCancelling: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationCancelSuccess,
        (state, { rentalApplication }) => ({
            ...state,
            isRentalApplicationCancelling: false,
            rentalApplication: rentalApplication
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationCancelFailed,
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestCancelFailed,
        (state, { errorMessage }) => ({
            ...state,
            isRentalApplicationCancelling: false,
            cancelErrorMessage: errorMessage
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalResetCancelErrorMessage,
        (state) => ({
            ...state,
            cancelErrorMessage: ''
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestCancelFailed,
        (state, { errorMessage }) => ({
            ...state,
            isRentalApplicationCancelling: false,
            cancelErrorMessage: errorMessage
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationStartRequested,
        (state,) => ({
            ...state,
            //Todo
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationStartSuccess,
        (state) => ({
            ...state,
            isRentalApplicationStarting: false,
            //Todo
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationStartFailed,
        (state) => ({
            ...state,
            //Todo
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationStartRentalRequestSuccess,
        (state) => ({
            ...state
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationStartRentalRequestFailed,
        (state) => ({
            ...state,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormSuccess,
        (state, { rentalApplicationForm }) => ({
            ...state,
            selectedRentalApplicationForm: rentalApplicationForm,
            isRentalApplicationFormLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormTemplatesRequested,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFileUploadLinkSendRequested,
        (state) => ({
            ...state,
            isRentalFileUploadLinkSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFileUploadLinkSendSuccess,
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFileUploadLinkSendFailed,
        (state) => ({
            ...state,
            isRentalFileUploadLinkSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormTemplatesSuccess,
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesSuccess,
        (state, { rentalFormTemplates: rentalFormTemplates }) => ({
            ...state,
            isRentalApplicationAddNewFormLoading: false,
            rentalFormTemplates: rentalFormTemplates
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormTemplatesFailed,
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationAdditionalFormTemplatesFailed,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormTemplatesRequested,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormTemplatesSuccess,
        (state, { rentalFormTemplates: rentalFormTemplates }) => ({
            ...state,
            isRentalApplicationAddNewFormLoading: false,
            rentalFormTemplates: rentalFormTemplates
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormTemplatesFailed,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestAddNewFormRequested,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestAddNewFormSuccess,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestAddNewFormFailed,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewFormRequested,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationAddNewFormSuccess,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationAddNewFormFailed,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormListRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormListLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormListSuccess,
        (state, { rentalApplicationForms }) => ({
            ...state,
            isRentalApplicationFormListLoading: false,
            rentalApplication: {
                ...state.rentalApplication,
                rentalApplicationForms: rentalApplicationForms
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormListFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormListLoading: false
        })
    ),

    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormListRequested,
        (state) => ({
            ...state,
            isRentalRequestFormListLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormListSuccess,
        (state, { rentalRequestForms }) => ({
            ...state,
            isRentalRequestFormListLoading: false,
            rentalRequest: {
                ...state.rentalRequest,
                rentalRequestForms: rentalRequestForms
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormListFailed,
        (state) => ({
            ...state,
            isRentalRequestFormListLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormFileListRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormFileListLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormFileListSuccess,
        (state, { rentalRequestFormFiles }) => ({
            ...state,
            isRentalApplicationFormListLoading: false,
            rentalRequest: {
                ...state.rentalRequest,
                rentalRequestFormFiles: rentalRequestFormFiles
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormFileListFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormListLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormFileListRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormFileListLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationFormFileListSuccess,
        (state, { rentalApplicationFormFiles }) => ({
            ...state,
            isRentalApplicationFormListLoading: false,
            rentalApplication: {
                ...state.rentalApplication,
                rentalApplicationFormFiles: rentalApplicationFormFiles
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationFormFileListFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormListLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRemoveFormRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormRemoving: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRemoveFormSuccess,
        (state, { formId }) => ({
            ...state,
            rentalApplication: {
                ...state.rentalApplication,
                rentalApplicationForms: [...state.rentalApplication.rentalApplicationForms.filter(x => x.id != formId).sort((a, b) => a.id - b.id)]
            },
            isRentalApplicationFormRemoving: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationRemoveFormFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormListLoading: false,
            isRentalApplicationFormRemoving: false,
        })
    ),


    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRemoveFormRequested,
        (state) => ({
            ...state,
            isRentalRequestFormListLoading: true,
            isRentalApplicationFormRemoving: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRemoveFormSuccess,
        (state) => ({
            ...state,
            isRentalRequestFormListLoading: false,
            isRentalApplicationFormRemoving: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestRemoveFormFailed,
        (state) => ({
            ...state,
            isRentalRequestFormListLoading: false,
            isRentalApplicationFormRemoving: false,
        })
    ),

    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRemoveCertnVerificationReportRequested,
        (state) => ({
            ...state,
            isRentalApplicationVerificationReportRemoving: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRemoveCertnVerificationReportSuccess,
        (state, { reportId }) => ({
            ...state,
            rentalApplication: {
                ...state.rentalApplication,
                rentalApplicationCertnReports: [...state.rentalApplication.rentalApplicationCertnReports.filter(x => x.id != reportId).sort((a, b) => a.id - b.id)]
            },
            isRentalApplicationVerificationReportRemoving: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRemoveCertnVerificationReportFailed,
        (state) => ({
            ...state,
            isRentalApplicationVerificationReportRemoving: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRemovePlaidVerificationReportRequested,
        (state) => ({
            ...state,
            isRentalApplicationVerificationReportRemoving: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRemovePlaidVerificationReportSuccess,
        (state, { reportId }) => ({
            ...state,
            rentalApplication: {
                ...state.rentalApplication,
                rentalApplicationPlaidReports: [...state.rentalApplication.rentalApplicationPlaidReports.filter(x => x.id != reportId).sort((a, b) => a.id - b.id)]
            },
            isRentalApplicationVerificationReportRemoving: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRemovePlaidVerificationReportFailed,
        (state) => ({
            ...state,
            isRentalApplicationVerificationReportRemoving: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRemoveFormFileRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormFileRemoving: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationRemoveFormFileSuccess,
        (state, { fileId }) => ({
            ...state,
            rentalApplication: {
                ...state.rentalApplication,
                rentalApplicationFormFiles: [...state.rentalApplication.rentalApplicationFormFiles.filter(x => x.id != fileId).sort((a, b) => a.id - b.id)]
            },
            isRentalApplicationFormFileRemoving: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationRemoveFormFileFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormFileRemoving: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRemoveFormFileRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormFileRemoving: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestRemoveFormFileSuccess,
        (state, { fileId }) => ({
            ...state,
            rentalRequest: {
                ...state.rentalRequest,
                rentalRequestFormFiles: [...state.rentalRequest.rentalRequestFormFiles.filter(x => x.id != fileId).sort((a, b) => a.id - b.id)]
            },
            isRentalApplicationFormFileRemoving: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestRemoveFormFileFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormFileRemoving: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormViewerRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormViewerSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false,
            rentalApplicationFormViewerDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPdfViewerReset,
        (state) => ({
            ...state,
            rentalApplicationFormViewerDocument: []
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormViewerFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormViewerRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormViewerSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false,
            rentalApplicationFormViewerDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormViewerFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationUploadedFileViewerRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationUploadedFileViewerSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false,
            rentalApplicationFormViewerDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApplicationUploadedFileViewerFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestUploadedFileViewerRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestUploadedFileViewerSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false,
            rentalApplicationFormViewerDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestUploadedFileViewerFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalCertnReportViewerRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalCertnReportViewerSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false,
            rentalApplicationFormViewerDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalCertnReportViewerFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPlaidReportViewerRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPlaidReportViewerSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false,
            rentalApplicationFormViewerDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalPlaidReportViewerFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPlaidIdentityReportViewerRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalPlaidIdentityReportViewerSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false,
            rentalApplicationFormViewerDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalPlaidIdentityReportViewerFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalCertnIdentityReportViewerRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalCertnIdentityReportViewerSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false,
            rentalApplicationFormViewerDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalCertnIdentityReportViewerFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormViewerLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorLoading: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormEditorLoading: false,
            rentalApplicationFormEditorDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorSuccess,
        (state, { document }) => ({
            ...state,
            isRentalApplicationFormEditorLoading: false,
            rentalApplicationFormEditorDocument: document
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorReset,
        (state) => ({
            ...state,
            rentalApplicationFormEditorDocument: initialState.rentalApplicationFormEditorDocument
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorReset,
        (state) => ({
            ...state,
            rentalApplicationFormEditorDocument: initialState.rentalApplicationFormEditorDocument
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormEditorFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormEditorFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorLoading: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorSaveRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorSaving: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorSaveRequested,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorSaving: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalFormEditorSaveSuccess,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorSaving: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestFormEditorSaveSuccess,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorSaving: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalFormEditorSaveFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorSaving: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestFormEditorSaveFailed,
        (state) => ({
            ...state,
            isRentalApplicationFormEditorSaving: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddNewManualRentalApplicationDocumentRequested,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddNewManualRentalApplicationDocumentSuccess,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddNewManualRentalApplicationDocumentFailed,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddNewManualRentalRequestDocumentRequested,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddNewManualRentalRequestDocumentSuccess,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddNewManualRentalRequestDocumentFailed,
        (state) => ({
            ...state,
            isRentalApplicationAddNewFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationStartRentalRequestRequested,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationStartRentalRequestSuccess,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationStartRentalRequestFailed,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalReadyForReviewRequested,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalReadyForReviewSuccess,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalReadyForReviewFailed,
        (state, { errorMessage, validationMessages }) => ({
            ...state,
            isRentalRequestStatusUpdating: false,
            rentalRequestValidationMessage: {
                errorMessage,
                validationMessages
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationResetValidationMessageRequested,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: false,
            rentalRequestValidationMessage: {
                errorMessage: '',
                validationMessages: []
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApproveRequested,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApproveSuccess,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalApproveFailed,
        (state, { errorMessage, validationMessages }) => ({
            ...state,
            isRentalRequestStatusUpdating: false,
            rentalRequestValidationMessage: {
                errorMessage,
                validationMessages
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationResendApplicantEmailRequested,
        (state) => ({
            ...state,
            isRentalApplicationEmailSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationResendApplicantEmailSuccess,
        (state) => ({
            ...state,
            isRentalApplicationEmailSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationResendApplicantEmailFailed,
        (state) => ({
            ...state,
            isRentalApplicationEmailSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalSignRequested,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalSignSuccess,
        (state) => ({
            ...state,
            isRentalRequestStatusUpdating: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalSignFailed,
        (state, { errorMessage, validationMessages }) => ({
            ...state,
            isRentalRequestStatusUpdating: false,
            rentalRequestValidationMessage: {
                errorMessage,
                validationMessages
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalAddNewCreditCheckRequested,
        (state) => ({
            ...state,
            isRentalApplicationCreditCheckFormSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalAddNewCreditCheckSuccess,
        (state) => ({
            ...state,
            isRentalApplicationCreditCheckFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalAddNewCreditCheckFailed,
        (state) => ({
            ...state,
            isRentalApplicationCreditCheckFormSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalReadyForReviewSuccess,
        (state) => ({
            ...state,
        })
    ),
    on(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalSignSuccess,
        (state) => ({
            ...state
        })
    ),
    on(clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApproveSuccess,
        (state) => ({
            ...state,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationUpdateLeasingDatesRequested,
        (state) => ({
            ...state,
            isRentalRequestDateSending: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationUpdateLeasingDatesSuccess,
        (state) => ({
            ...state,
            isRentalRequestDateSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationUpdateLeasingDatesFailed,
        (state) => ({
            ...state,
            isRentalRequestDateSending: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestActivitiesRequested,
        (state) => ({
            ...state,
            isRentalRequestActivitiesLoaded: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestActivitiesLoaded,
        (state, { rentalRequestActivities, pageNo, pageSize, totalRecords }) => ({
            ...state,
            isRentalRequestActivitiesLoaded: true,
            rentalRequestActivities: state.rentalRequestActivities.concat(rentalRequestActivities),
            activityTotalRecords: totalRecords,
            activittPageSize: pageSize,
            activityPageNo: pageNo,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestActivitiesFailed,
        (state) => ({
            ...state,
            isRentalRequestActivitiesLoaded: true,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestActivitiesMoreRequested,
        (state) => ({
            ...state,
            isActivitiesLoadingMore: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestActivitiesMoreLoaded,
        (state, { rentalRequestActivities, pageNo, pageSize, totalRecords }) => ({
            ...state,
            rentalRequestActivities: state.rentalRequestActivities.concat(rentalRequestActivities),
            activityTotalRecords: totalRecords,
            activittPageSize: pageSize,
            activityPageNo: pageNo,
            isActivitiesLoadingMore: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestActivitiesMoreFailed,
        (state) => ({
            ...state,
            isActivitiesLoadingMore: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationToggleShowRentalRequestActivities,
        (state, { ifShowRentalRequestActivities }) => ({
            ...state,
            ifShowRentalRequestActivities: ifShowRentalRequestActivities,
            rentalRequestActivities: [],
            activityTotalRecords: 0,
            activittPageSize: initialState.activittPageSize,
            activityPageNo: initialState.activityPageNo
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalInfoUpdateRequested,
        (state) => ({
            ...state,
            isRentalApplicationinfoUpdating: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalInfoUpdateFailed,
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalInfoUpdateSuccess,
        (state) => ({
            ...state,
            isRentalApplicationinfoUpdating: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemsForRentalReqest,
        (state) => ({
            ...state,
            isRentalItemsLoaded: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemsForRentalReqestFailed,
        (state) => ({
            ...state,
            isRentalItemsLoaded: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemsForRentalReqestLoaded,
        (state, { rentalItems }) => ({
            ...state,
            rentalRequest: {
                ...state.rentalRequest,
                rentalItems: rentalItems,
            },
            isRentalItemsLoaded: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemOptionsForRentalReqest,
        (state) => ({
            ...state,
            isRentalItemOptionsLoaded: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemOptionsForRentalReqestLoaded,
        (state, { rentalItemOptions, totalRecords }) => ({
            ...state,
            rentalItemOptions: state.rentalItemOptions.concat(rentalItemOptions),
            isRentalItemOptionsLoaded: true,
            rentalItemOptionsTotalRecords: totalRecords
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemTypesForRentalReqestLoaded,
        (state, { rentalItemTypes }) => ({
            ...state,
            rentalItemTypes: rentalItemTypes,
            isRentalItemTypesLoaded: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRemoveRentalItemFromRentalReqest,
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddRentalItemsToRentalReqest,
        (state) => ({
            ...state,
            isRentalItemUpdating: true,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationAddRentalItemsToRentalReqestSucceed,
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRemoveRentalItemFromRentalReqestSucceed,
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationAddRentalItemsToRentalReqestFailed,
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRemoveRentalItemFromRentalReqestFailed,
        (state) => ({
            ...state,
            isRentalItemUpdating: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemOptionsForRentalReqestFailed,
        (state) => ({
            ...state,
            isRentalItemOptionsLoaded: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationGetRentalItemTypesForRentalReqestFailed,
        (state) => ({
            ...state,
            isRentalItemTypesLoaded: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemTypesForRentalReqest,
        (state) => ({
            ...state,
            isRentalItemTypesLoaded: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemOptionsForRentalReqest,
        (state) => ({
            ...state,
            isRentalItemOptionsLoaded: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetRentalItemTypeSelected,
        (state, { rentalItemType }) => ({
            ...state,
            rentalItemSelected: rentalItemType,
            rentalItemOptionsPagination: initialState.rentalItemOptionsPagination,
            rentalItemOptions: []
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalItemStateReset,
        (state) => ({
            ...state,
            rentalItemOptions: [],
            isRentalItemTypesLoaded: false,
            isRentalItemOptionsLoaded: false,
            rentalItemTypes: [],
            isRentalItemUpdating: false,
            rentalItemSelected: '',
            rentalItemOptionsPagination: initialState.rentalItemOptionsPagination
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalItemOptionsPageChange,
        (state, { page }) => ({
            ...state,
            rentalItemOptionsPagination: {
                ...state.rentalItemOptionsPagination,
                page: page
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalItemOptionsNextPage,
        (state) => ({
            ...state,
            rentalItemOptionsPagination: {
                ...state.rentalItemOptionsPagination,
                page: state.rentalItemOptionsPagination.page + 1
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationLeasingDocumentSignatureResetRequested,
        (state) => ({
            ...state,
            isLeasingSignatureReseting: true,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationLeasingDocumentSignatureResetSucceed,
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationLeasingDocumentSignatureResetFailed,
        (state) => ({
            ...state,
            isLeasingSignatureReseting: false,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationGetLeadInfoByLeadIdLoaded,
        (state, { leadInfo }) => ({
            ...state,
            rentalApplicationLeadInfo: leadInfo,
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationUpdateApplicantLeadInfo,
        (state, { leadId, firstName, lastName, emails, phoneNumbers }) => ({
            ...state,
            rentalApplicationLeadInfo: {
                ...state.rentalApplicationLeadInfo,
                id: leadId,
                firstName: firstName,
                lastName: lastName,
                emails: emails,
                phoneNumbers: phoneNumbers
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationUnitsForRentalRequestLoaded,
        (state, { units }) => ({
            ...state,
            rentalApplicationUnitsToSelect: units
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestUnitUpdateRequested,
        (state) => ({
            ...state,
            isRentalRequestUnitUpdating: true
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalRequestUnitUpdateSucceed,
        (state, { unitId, rentPrice }) => ({
            ...state,
            isRentalRequestUnitUpdating: false,
            rentalRequest: {
                ...state.rentalRequest,
                buildingPropertyInventoryId: unitId,
                rentalPrice: rentPrice
            }
        })
    ),
    on(
        clientDialogApplicationRentalApplicationFailedActions.actionClientDialogApplicationRentalRequestUnitUpdateFailed,
        (state) => ({
            ...state,
            isRentalRequestUnitUpdating: false
        })
    ),
    on(
        clientDialogApplicationRentalApplicationActions.actionClientDialogApplicationRentalApplicationReset,
        () => initialState
    )
)

export const clientDialogClientRentalApplicationReducer = (
    state: ClientDialogClientRentalApplicationState | undefined,
    action: Action
) => {
    return reducer(state, action);
};

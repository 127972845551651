<div class="unit-table-header">
    <div class="row">
      <div class="col-xl-4 col-lg-12">
        <div class="row">
          <div class="col-sm-6">
            <span>Beds/Baths</span>
          </div>
          <div class="col-sm-6">
            <span>#Unit</span>
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-12">
        <div class="row">
          <div class="col-sm-2">
            <span>Price</span>
          </div>
          <div class="col-sm-2">
            <span>Special Price</span>
          </div>
          <div class="col-sm-2">
            <span>Sqft</span>
          </div>
          <div class="col-sm-3">
            <span>Made Ready Date</span>
          </div>
          <div class="col-sm-3">
            <span>Vacancy Date</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="unit-list-container">
    <ng-container *ngFor="let unit of (units$ | async); let i = index">
      <div 
        class="row unit-detail"
        [ngClass]="{'selected-unit': selectedUnit === unit, 'hover-unit': hoveredUnit === unit}"
        (click)="selectUnit(unit)"
        (mouseenter)="hoveredUnit = unit"
        (mouseleave)="hoveredUnit = null">
        <div class="col-xl-4 col-lg-12">
          <div class="row">
            <div class="col-sm-6">
              <div class="unit-detail-info">{{unit.beds}} BD | {{unit.baths}} BA</div>
            </div>
            <div class="col-sm-6">
              <div class="unit-detail-info">{{unit.yardiUnitIdentifier}}</div>
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-lg-12">
          <div class="row">
            <div class="col-sm-2">
                <div class="unit-detail-info">
                  <ng-container *ngIf="unit.price > 0; else priceNA">
                    ${{unit.price | number: '1.2-2'}}
                  </ng-container>
                  <ng-template #priceNA>Not Available</ng-template>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="unit-detail-info">
                  <ng-container *ngIf="unit.specialPrice > 0; else specialPriceNA">
                    ${{unit.specialPrice | number: '1.2-2'}}
                  </ng-container>
                  <ng-template #specialPriceNA>Not Available</ng-template>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="unit-detail-info">
                  <ng-container *ngIf="unit.unitSquareFeet > 0; else sqftNA">
                    {{unit.unitSquareFeet | number: '1.0-0'}}
                  </ng-container>
                  <ng-template #sqftNA>Not Available</ng-template>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="unit-detail-info">{{unit.madeReadyDate | date: 'MMM d, y'}}</div>
              </div>
              <div class="col-sm-3">
                <div class="unit-detail-info">{{unit.vacancyDate | date: 'MMM d, y'}}</div>
              </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="action-buttons dq-flex-centered">
    <dq-spinner-button type="button" (click)="updateUnit()"
      [options]="spinnerCreateButtonOptions"></dq-spinner-button>
  </div>